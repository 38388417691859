import React from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {

  const handleNavLinkClick = () => {
    const navbarToggler = document.querySelector('.navbar-toggler');
    const navbarCollapse = document.querySelector('.navbar-collapse');

    if (navbarCollapse.classList.contains('show')) {
      navbarToggler.click(); // This will toggle the collapse, effectively closing the menu
    }
  };

  return (
    <nav className="navbar navbar-expand-lg border-bottom border-body">
      <div className="container-fluid">
        <div className='d-flex flex-column justify-content-center align-items-center w-md-100'>
          <Link to="/" onClick={handleNavLinkClick}>
            <img id="logo" src="/images/jackie-button.jpg" alt="MyApp Logo" className="ms-lg-5 m-2 rounded-circle border border-primary border-4" />
          </Link> 
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarColor01"
            aria-controls="navbarColor01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="collapse navbar-collapse justify-content-end me-lg-5 m-2 text-lg-center" id="navbarColor01">
          <ul className="navbar-nav mb-2 mb-lg-0">
            <li className="nav-item me-2">
              <Link className="nav-link active fs-4 fw-semibold" to="/" onClick={handleNavLinkClick}>Home</Link>
            </li>
            <li className="nav-item me-2">
              <Link className="nav-link fs-4 fw-semibold" to="/about-jackie" onClick={handleNavLinkClick}>About Jackie</Link>
            </li>
            <li className="nav-item me-2">
              <Link className="nav-link fs-4 fw-semibold" to="/endorsements" onClick={handleNavLinkClick}>Endorsements</Link>
            </li>
            <li className="nav-item me-2">
              <Link className="nav-link fs-4 fw-semibold" to="/volunteer" onClick={handleNavLinkClick}>Volunteer</Link>
            </li>
            <li className="nav-item me-2">
              <Link className="nav-link fs-4 fw-semibold" to="/donate" onClick={handleNavLinkClick}>Donate</Link>
            </li>
            <li className="nav-item me-2">
              <Link className="nav-link fs-4 fw-semibold" to="/contact" onClick={handleNavLinkClick}>Contact</Link>
            </li>
            <li className="nav-item me-2">
              <Link className="nav-link fs-4 fw-semibold" to="/privacy-policy" onClick={handleNavLinkClick}>Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
