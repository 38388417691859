import React from 'react';

const Hero = () => {
  return (
    <div className='container-fluid'>
      <div className="row min-vh-75 align-items-center d-flex align-items-md-stretch">
        <div className="hero-section col-md-12 p-0 d-flex align-items-end align-items-lg-start">
          <div><h1 id="banner-header" className='m-2 m-lg-5 bg-darken p-3 fs-1'>Preserve The Bridgeway Median<br/>For Emergency Service Vehicles</h1></div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
