import React from 'react';
import HeroSmall from '../components/HeroSmall'

const MeetJackie = () => {
  return (
    <div>
      <HeroSmall />
      <div className="container py-5">
        <div className="row">
          <div className="col text-center mb-4">
            <h1 className="display-4 fw-bold jackie-green">Meet the Candidate</h1>
            <p className="lead">Join Jackie Amrikhas at these upcoming events to discuss her vision for Sausalito and get to know her better.</p>
          </div>
        </div>

        <div className="row align-items-stretch justify-content-center">
          <div className="col-md-6 mb-3">
            <div className="event-card">
              <h4 className="card-title jackie-blue">Community Debate</h4>
              <p className="card-text">
                <strong>Date:</strong> &#91;Insert Date&#93; <br />
                <strong>Time:</strong> &#91;Insert Time&#93; <br />
                <strong>Location:</strong> &#91;Insert Location&#93;
              </p>
              <p className="card-text">Join Jackie Amrikhas for a debate where she will discuss her vision for Sausalito and answer your questions.</p>
              <button className="btn btn-primary me-2">Add to Calendar</button>
              <button className="btn btn-secondary">Get Directions</button>
            </div>
          </div>

          <div className="col-md-6 mb-3">
            <div className="event-card">
              <h4 className="card-title jackie-blue">Coffee with Jackie</h4>
              <p className="card-text">
                <strong>Date:</strong> &#91;Insert Date&#93; <br />
                <strong>Time:</strong> &#91;Insert Time&#93; <br />
                <strong>Location:</strong> &#91;Insert Local Coffee Shop Name&#93;
              </p>
              <p className="card-text">Stop by for a casual coffee chat with Jackie. Share your concerns and learn more about her plans for the City Council.</p>
              <button className="btn btn-primary me-2">Add to Calendar</button>
              <button className="btn btn-secondary">Get Directions</button>
            </div>
          </div>

          <div className="col-md-6 mb-3">
            <div className="event-card">
              <h4 className="card-title jackie-blue">City Town Hall Meeting</h4>
              <p className="card-text">
                <strong>Date:</strong> &#91;Insert Date&#93; <br />
                <strong>Time:</strong> &#91;Insert Time&#93; <br />
                <strong>Location:</strong> &#91;Insert Location&#93;
              </p>
              <p className="card-text">Attend this town hall meeting to hear Jackie's policy positions and engage in an open forum with fellow residents.</p>
              <div><button className="btn btn-primary me-2">Add to Calendar</button>
              <button className="btn btn-secondary">Get Directions</button>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-3">
            <div className="event-card">
              <h4 className="card-title jackie-blue">Neighborhood Meet and Greet</h4>
              <p className="card-text">
                <strong>Date:</strong> &#91;Insert Date&#93; <br />
                <strong>Time:</strong> &#91;Insert Time&#93; <br />
                <strong>Location:</strong> &#91;Insert Neighborhood Park or Community Center&#93;
              </p>
              <p className="card-text">Join Jackie for a meet and greet event in your neighborhood. It's a great opportunity to discuss local issues and get to know her better.</p>
              <button className="btn btn-primary me-2">Add to Calendar</button>
              <button className="btn btn-secondary">Get Directions</button>
            </div>
          </div>

          <div className="col-md-6 mb-3">
            <div className="event-card">
              <h4 className="card-title jackie-blue">Community Volunteer Day</h4>
              <p className="card-text">
                <strong>Date:</strong> &#91;Insert Date&#93; <br />
                <strong>Time:</strong> &#91;Insert Time&#93; <br />
                <strong>Location:</strong> &#91;Insert Location&#93;
              </p>
              <p className="card-text">Participate in a community service event with Jackie. Help beautify Sausalito and see firsthand her commitment to our city.</p>
              <button className="btn btn-primary me-2">Add to Calendar</button>
              <button className="btn btn-secondary">Get Directions</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetJackie;