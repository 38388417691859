import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Nav from './components/NavBar';
import FloatingTab from './components/FloatingTab'
import Footer from './components/Footers'
import Home from './pages/Home';
import Endorsements from './pages/Endorsements'
import MeetJackie from './pages/MeetJackie'
import AboutJackie from './pages/AboutJackie'
import Contact from './pages/Contact'
import Donate from './pages/Donate'
import Volunteer from './pages/Volunteer'
import Privacy from './pages/Privacy';

function App() {
  return (
    <div id='main'>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/endorsements" element={<Endorsements />} />
        <Route path="/about-jackie" element={<AboutJackie />} />
        <Route path="/meet-jackie" element={<MeetJackie />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/volunteer" element={<Volunteer />} />
      </Routes>
      <Footer/>
      <FloatingTab />
    </div>
  );
}

export default App;