import React from 'react';
import Heros from '../components/Heros'

const Home = () => {
  return (
    <div>
      <Heros />

      <div className="container my-5">
        <div className="row">
          <div className="col text-center">
            <h1 className="display-4 fw-bold">STOP THE MONSTER? SAVE THE MEDIAN<br />
              <span className='jackie-blue'>Vote </span>
              <span className='jackie-green'>Jackie Amrikhas, CPA </span> 
              <span className='jackie-blue'>for City Council</span> 
            </h1>
          </div>
          </div>
          <div className="row featurette my-5 align-items-center">
            <div className="col-md-7">
              <h2 className="featurette-heading fw-bold jackie-blue lh-1 text-md-start text-center">No Construction Monsters.<br /><span className=" jackie-green jackie-green">Keeping Sausalito serene and beautiful.</span></h2>
              <p className="lead">Jackie is dedicated to ensuring that new developments are in harmony with the existing community, without overwhelming the city’s character.</p>
            </div>
            <div className="col-md-5 d-flex justify-content-md-end justify-content-center mt-md-0 mt-3">
              <img className='img-fluid' src='/images/feature1.jpg' alt='Featurette 1' />
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette my-5 align-items-center">
            <div className="col-md-7 order-md-2 d-flex flex-column justify-content-end">
              <h2 className="featurette-heading fw-bold jackie-blue lh-1 text-md-start text-center">Focus on Road Repair.<br /><span className=" jackie-green">Improving our infrastructure.</span></h2>
              <p className="lead">Send Jackie your pothole pictures and any concerns you may have: <a href="mailto:JackieforSausalito@gmail.com">JackieforSausalito@gmail.com</a></p>
            </div>
            <div className="col-md-5 order-md-1 d-flex justify-content-md-start justify-content-center mt-md-0 mt-3">
              <img className='img-fluid' src='/images/pothole.jpg' alt='Featurette 2' />
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette my-5 align-items-center">
            <div className="col-md-7">
              <h2 className="featurette-heading fw-bold jackie-blue lh-1 text-md-start text-center" >Preserve the Bridgeway Median.<br /><span className=" jackie-green">Protecting our natural beauty.</span></h2>
              <p className="lead" style={{ maxWidth: '1024px' }}>Jackie is committed to preserving the Bridgeway Median and maintaining its natural landscape for future generations.</p>
            </div>
            <div className="col-md-5 d-flex justify-content-md-end justify-content-center mt-md-0 mt-3">
              <img className='img-fluid' src='/images/bridgeway-golden.jpg' alt='Featurette 3' />
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette my-5 align-items-center">
            <div className="col-md-7 order-md-2 d-flex flex-column justify-content-end">
              <h2 className="featurette-heading fw-bold jackie-blue lh-1 text-md-end text-center">Let Residents be Heard.<br /><span className="jackie-green">Empowering local voices.</span></h2>
            </div>
            <div className="col-md-5 order-md-1 d-flex justify-content-md-start justify-content-center mt-md-0 mt-3">
              <img className='img-fluid' src='/images/voices.png' alt='Featurette 4' />
            </div>
          </div>


          <hr className="featurette-divider" />

          <div className="row featurette my-5 align-items-center">
            <div className="col-md-7">
              <h2 className="featurette-heading fw-bold jackie-blue lh-1 text-md-start text-center">Balance the Budget.<br /><span className="  jackie-green">Transparent and honest fiscal management.</span></h2>
              <p className="lead ">Stop the shell game. Jackie is committed to a balanced budget that adds up and doesn't rely on misleading numbers.</p>
            </div>
            <div className="col-md-5 d-flex justify-content-md-end justify-content-center mt-md-0 mt-3">
              <img className='img-fluid' src='/images/budget.jpg' alt='Featurette 5' />
            </div>
          </div>

          <hr className="featurette-divider" />

          <div className="row featurette my-5 align-items-center">
            <div className="col-md-7 order-md-2 d-flex flex-column justify-content-end">
              <h2 className="featurette-heading fw-bold jackie-blue lh-1 text-md-start text-center">
                Preserve Sausalito's Waterfront.<br />
                <span className="jackie-green mt-3">Economic sustainability through a vibrant waterfront.</span>
              </h2>
              <p className="lead">
                City revenue cannot rely solely on the tourist industry and parking. Jackie believes in preserving Sausalito's waterfront, which supports approximately 2,000 boats and generates revenue through sales tax, business license, and unsecured property taxes. Unlike the City of Alameda, which replaced its waterfront with high-end condominiums, Sausalito's waterfront is a vital part of our community, providing jobs, revenue, and technological advancement for the blue economy. Let’s not make the same mistake—protect and enhance our waterfront.
              </p>
            </div>
            <div className="col-md-5 order-md-1 d-flex justify-content-md-start justify-content-center mt-md-0 mt-3">
              <img className="img-fluid" src="/images/working-waterfront.jpg" alt="Featurette 4" />
            </div>
          </div>


        
      </div>
      <h2 className='text-center m-3 fw-bold'><span className='jackie-green'>JACKIE IS A CPA-SHE KNOWS NUMBERS</span><br />
        JackieForSausalito.com</h2>
    </div>
  );
};

export default Home;