import React from 'react';

const ServiceCards = () => {
    return (
        <section className="donation-section py-5">
            <div id="WhatWeDo" className="container">
                <div className="row justify-content-center">
                    
                    {/* Venmo Donation Section */}
                    <div className="col-md-4 text-center mb-4 d-flex flex-column justify-content-between">
                        <h2 className="fw-bold jackie-blue">Donate via Venmo</h2>
                        <p className="lead">Make a custom donation through Venmo.</p>
                        <a 
                            href="https://account.venmo.com/u/Jacqueline-Amrikhas" 
                            target="_blank" 
                            rel="noreferrer"
                        >
                            <div className="mb-3">
                                <img 
                                    src="/images/jackie venmo.png" 
                                    alt="Venmo Logo" 
                                    className="img-fluid w-75" 
                                />
                            </div>
                        </a>
                        <a 
                            href="https://account.venmo.com/u/Jacqueline-Amrikhas" 
                            target="_blank" 
                            rel="noreferrer" 
                            className="btn btn-primary btn-lg border-0 w-100"
                        >
                            Donate with Venmo
                        </a>
                    </div>

                    {/* Other Methods Donation Section */}
                    <div className="col-md-4 text-center mb-4 d-flex flex-column justify-content-between align-content-center">
                        <h2 className="fw-bold jackie-blue">Donate via Other Methods</h2>
                        <p className="lead">Enter any amount and donate using various methods.</p>
                        <a 
                            href="https://square.link/u/rqq5JcSt" 
                            target="_blank" 
                            rel="noreferrer"
                        >
                            <div className="mb-3">
                                <img 
                                    src="/images/squarelogo.svg" 
                                    alt="Square Logo" 
                                    className="img-fluid w-75" 
                                />
                            </div>
                        </a>
                        <a 
                            href="https://square.link/u/rqq5JcSt" 
                            target="_blank" 
                            rel="noreferrer" 
                            className="btn btn-primary btn-lg border-0 w-100"
                        >
                            Donate with Other Methods
                        </a>
                    </div>

                    {/* Mail a Check Section */}
                    <div className="col-md-4 text-center mb-4 d-flex flex-column justify-content-between">
                        <h2 className="fw-bold jackie-blue">Mail a Check</h2>
                        <p className="lead">Send a paper check to the address below:</p>
                        <p className="lead">
                            <strong>Jacqueline Amrikhas Sausalito City Council 2024</strong><br />
                            180 Harbor Drive Suite 204B<br />
                            Sausalito, CA 94965
                        </p>
                        <p className="lead">
                            Please include your full name, address, occupation, and company name with your check. Donations are limited to $500.
                        </p>
                    </div>

                    {/* Contact Information Section */}
                    <div className="text-center mt-5">
                        <p className="lead">
                            For any questions or further information, please contact us at: <br />
                            <a href="mailto:jackieforsausalito@gmail.com">jackieforsausalito@gmail.com</a><br />
                            <a href="http://jackieforsausalito.com" target="_blank" rel="noopener noreferrer">jackieforsausalito.com</a><br />
                            415-331-2137
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServiceCards;
