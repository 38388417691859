import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const Volunteer = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    subscribe: false,
    volunteerOptions: [],
    otherVolunteer: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      if (name === 'subscribe') {
        setFormData({
          ...formData,
          subscribe: checked,
        });
      } else {
        setFormData({
          ...formData,
          volunteerOptions: checked
            ? [...formData.volunteerOptions, value]
            : formData.volunteerOptions.filter((option) => option !== value),
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_mkubqr9', 'template_vol', e.target, 'ZRnaxOFhnzpDdbvbK')
      .then(
        (result) => {
          console.log(result.text);
          setIsSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );

    e.target.reset();
  };

  return (
    <div className="container my-5">
      <h1 className="display-4 text-center fw-bold jackie-green my-5">Volunteer with Us!</h1>
      <p className="lead text-center">
        Join the campaign by volunteering in various ways. Let us know how you'd like to help!
      </p>
      <div className='row'>
        <div className='col-md-6'>

        

      {isSubmitted ? (
        <div className="alert alert-success" role="alert">
          Thank you for volunteering! We will get back to you soon.
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="firstName" className="form-label">
              First Name
            </label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="lastName" className="form-label">
              Last Name
            </label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-3">
            <label className="form-label">How would you like to volunteer?</label>

            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="yardSign"
                name="volunteerOptions"
                value="Post a yard sign"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="yardSign">
                Post a yard sign
              </label>
            </div>

            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="knockDoors"
                name="volunteerOptions"
                value="Knock on doors in my neighborhood"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="knockDoors">
                Knock on doors in my neighborhood
              </label>
            </div>

            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="houseParty"
                name="volunteerOptions"
                value="Host a house party"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="houseParty">
                Host a house party
              </label>
            </div>

            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="hostCoffee"
                name="volunteerOptions"
                value="Host a house party"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="hostCoffee">
                Host a coffee meeting with Jackie and invite your neighbors and friends. 
              </label>
            </div>

            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="adminTasks"
                name="volunteerOptions"
                value="Do administrative tasks"
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="adminTasks">
                Do administrative tasks
              </label>
            </div>

            <div className="mb-3">
              <label htmlFor="otherVolunteer" className="form-label">
                Other (please specify):
              </label>
              <input
                type="text"
                className="form-control"
                id="otherVolunteer"
                name="otherVolunteer"
                value={formData.otherVolunteer}
                onChange={handleChange}
              />
            </div>
          </div>

          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      )}
      </div>
        <div className='col-md-6'>
            <img className='img-fluid rounded-1' src='/images/jackie-sailing.jpg' alt=''/>
        </div>
      </div>
    </div>
  );
};

export default Volunteer;
