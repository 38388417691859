import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const ContactButtons = () => {
  const navigate = useNavigate(); // Initialize navigate function

  const handleDonateClick = () => {
    navigate('/donate'); // Navigate to the /donate route
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-primary rounded-pill border-secondary border-3 fs-5 fw-bold"
        onClick={handleDonateClick}
      >
        Donate
      </button>
    </div>
  );
};

export default ContactButtons;
