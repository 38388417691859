import React from 'react';

const Privacy = () => {
  return (
    <div className='container my-5'>
      <h1 className="display-4 fw-bold jackie-green text-center mb-5">Privacy Policy</h1>
      <div>
        <h2 className='jackie-blue'>October 17, 2024<br />Campaign Name: Jackie for Sausalito<br />Campaign Support Email:&nbsp;<a href="mailto:jackieforsausalito@gmail.com">jackieforsausalito@gmail.com</a></h2>
      </div>
      <div>
        <p>Personally Identifiable Information will not be sold or shared with third parties for promotional or marketing purposes</p>
        <p>This privacy policy explains how the above-named campaign (the “campaign,” “we,” or “us”) collects, uses, and discloses information about you when you access or use our websites and mobile sites that link to this privacy policy (collectively, the “site”).</p>
        <p>We may change this privacy policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice (such as adding a statement to our website homepage or sending you a notification). We encourage you to review the privacy policy whenever you access the site or otherwise interact with us to stay informed about our information practices and the choices available to you.</p>
        <p>If you have any questions about this privacy policy, please contact us at the campaign support e-mail address listed at the beginning of this privacy policy (“campaign support”).</p>
      </div>
      <div>
        <h2 className='jackie-blue'>Collection of Information</h2>
      </div>
      <div>
        <h3 className='h4'>Information You Provide to Us</h3>
      </div>
      <div>
        <p>We collect information you provide directly to us. For example, we collect information when you sign up to receive updates, request information, fill out a form, sign a petition, sign up as a volunteer, sign up for an event, participate in a contest or promotion, make a donation or purchase, communicate with us via third party social media sites, request support, send us an email, or otherwise communicate with us. The types of information we may collect include your name, contact information (such as email address, postal address, and phone number), credit card and other payment information (although payment information may be collected directly by a third-party processor), and any other information you choose to provide.</p>
        <p>In addition, the applicable election regulatory body may require us to collect certain personal information from donors.</p>
      </div>
      <div>
        <h3 className='h4'>Automatically Collected Information</h3>
      </div>
      <div>
        <p>When you access or use our site, we automatically collect information about you, including:</p>
        <h3 className='h4'>Log and Usage Information</h3>
        <p>We collect information related to your access to and use of the site, including the type of browser you use, app version, access times, pages viewed, your IP address, and the webpages you visited before and after navigating to our site.</p>
        <h3 className='h4'>Device Information</h3>
        <p>We collect information about the computer or mobile device you use to access our site, including the hardware model, operating system and version, unique device identifiers, and mobile network information.</p>
        <h3 className='h4'>Transactional Information</h3>
        <p>If you make a donation or purchase, we may collect information about the transaction, such as donation amount, purchase price, product details, and date and location of the transaction.</p>
        <h3 className='h4'>Location Information</h3>
        <p>We may derive the approximate location of your device, such as from your IP address.</p>
        <h3 className='h4'>Information Collected by Cookies and Other Tracking Technologies</h3>
        <p>We (and our service providers) use different technologies to collect information, including cookies and web beacons. Cookies are small data files stored on your hard drive or in device memory that help us improve our site and your experience, see which areas and features of our site are popular, and count visits. Web beacons (also known as “pixel tags” or “clear GIFs”) are electronic images that may be used in our site or emails and help deliver cookies, count visits, and understand usage and campaign effectiveness. For more information about cookies and how to disable them, see your choices below. For more information about the use of cookies and similar technology on our online services please review this privacy policy and the advertising and analytics and cookies sections below.</p>
      </div>
      <div>
        <h2 className='jackie-blue'>Information We Collect from Other Sources</h2>
      </div>
      <div>
        <p>We and our service providers may also obtain information about you from other sources and combine that with information we collect about you on our site. For example, we may collect voter file data from state parties and other affiliated organizations, from public databases, and from private organizations.</p>
      </div>
      <div>
        <h2 className='jackie-blue'>Campaign Purposes and Uses of Information</h2>
      </div>
      <div>
        <p>We may use the information we collect to:</p>
        <ul>
          <li>
            <p>Provide, maintain, and improve our site;</p>
          </li>
          <li>
            <p>Provide and deliver the information you request, process donations and transactions and send you related information, including confirmations and receipts,</p>
          </li>
          <li>
            <p>Send you technical notices, updates, security alerts, and support and administrative messages and provide technical support;</p>
          </li>
          <li>
            <p>Respond to your emails, submissions, comments, questions and requests; request feedback or support, and otherwise contact you about your participation in the campaign and use of the site;</p>
          </li>
          <li>
            <p>Communicate with you about the campaign, such as sending you information to keep you informed about various issues, events, activities, and volunteer opportunities, and provide news and information we think will be of interest to you (see your choices below for information about how to opt out of these communications at any time);</p>
          </li>
          <li>
            <p>To solicit volunteers, donations and support for the campaign and for other candidates, campaigns, issues and organizations that we support and to connect you with other volunteers;</p>
          </li>
          <li>
            <p>Contact you if applicable election laws require us to request additional information from you;</p>
          </li>
          <li>
            <p>Remind you to vote and register to vote and assist you in finding your registration information, polling location and campaign events near you;</p>
          </li>
          <li>
            <p>Monitor and analyze trends, usage, and activities in connection with our site;</p>
          </li>
          <li>
            <p>Personalize the site and provide advertisements, content or features based on your preferences, interests, and browsing and online activities;</p>
          </li>
          <li>
            <p>Facilitate contests, sweepstakes, and promotions and process and deliver entries and rewards;</p>
          </li>
          <li>
            <p>Detect, investigate and prevent fraudulent transactions and other illegal activities and protect the rights and property of the campaign and others; and</p>
          </li>
          <li>
            <p>Carry out any other purpose described to you at the time the information was collected.</p>
          </li>
        </ul>
      </div>
      <div>
        <h2 className='jackie-blue'>Sharing of Information</h2>
      </div>
      <div>
        <p>We may share information about you as follows or as otherwise described in this privacy policy:</p>
        <ul>
          <li>
            <p>With vendors, service providers, volunteers, and consultants who need access to such information to carry out work on our behalf;</p>
          </li>
          <li>
            <p>With candidates, campaigns, political or nonprofit organizations, groups or causes that we believe have similar political viewpoints, principles or objectives or share similar goals and with organizations that facilitate communications and information sharing among such groups or otherwise assist such groups;</p>
          </li>
          <li>
            <p>With other participants in a joint fundraising committee;</p>
          </li>
          <li>
            <p>To report required information to the applicable elections regulatory body;</p>
          </li>
          <li>
            <p>In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law or legal process, including lawful requests by public authorities to meet national security or law enforcement requirements;</p>
          </li>
          <li>
            <p>When we believe in good faith that we are lawfully authorized or required to do so or that doing so is reasonably necessary or appropriate to comply with the law or legal processes or respond to lawful requests, claims or legal authorities, including responding to lawful subpoenas, warrants, or court orders;</p>
          </li>
          <li>
            <p>If we believe your actions are inconsistent with the spirit or language of our user agreements or policies, or to protect the rights, property, and safety of the campaign, our users, or others;</p>
          </li>
          <li>
            <p>In connection with a merger, sale of assets, financing, or acquisition of all or a portion of our business to another company, or in the event of a bankruptcy; and</p>
          </li>
          <li>
            <p>With your consent or at your direction.</p>
          </li>
        </ul>
      </div>
      <div>
        <h2 className='jackie-blue'>Advertising and Analytics</h2>
      </div>
      <div>
        <p>We may allow others to provide analytics services on our behalf, and others may use cookies, web beacons, or other tracking technologies to collect information about your use of the site over time. This information may be used by us and others to, among other things, analyze and track data, determine the popularity of certain content, and better understand online activity.</p>
        <p>Some of the analytics providers we may work with include:</p>
        <ul>
          <li>
            <p>Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics uses cookies to help us analyze how users interact with our site. The information generated by the cookie about your use of the site (including your IP address) will be transmitted to and stored by Google on servers in the United States. For more information on how Google uses your information, please visit the <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Google Privacy Policy</a>.</p>
          </li>
        </ul>
      </div>
      <div>
        <h2 className='jackie-blue'>Your Choices</h2>
      </div>
      <div>
        <p>You have choices regarding the use of your information:</p>
        <h3 className='h4'>Email Communications</h3>
        <p>You may opt out of receiving promotional emails from us by following the instructions in those emails. If you opt out, we may still send you non-promotional emails, such as emails about your donations or our ongoing business relations.</p>
        <h3 className='h4'>Cookies</h3>
        <p>Most web browsers are set to accept cookies by default. You can remove or reject cookies, but be aware that such action could affect the availability and functionality of the site. For more information about how to manage cookies, please visit your browser’s help page or the following links:</p>
        <ul>
          <li><a href="https://www.aboutcookies.org/" target="_blank" rel="noreferrer">AboutCookies.org</a></li>
          <li><a href="http://www.allaboutcookies.org/" target="_blank" rel="noreferrer">AllAboutCookies.org</a></li>
        </ul>
        <h3 className='h4'>Do Not Track</h3>
        <p>Your browser may offer you a “Do Not Track” option. We do not currently respond to “Do Not Track” signals. To learn more about “Do Not Track,” please visit <a href="https://allaboutdnt.com/" target="_blank" rel="noreferrer">AllAboutDNT.com</a>.</p>
        <h3 className='h4'>Third-Party Advertising</h3>
        <p>You may opt out of targeted advertising from certain third-party vendors by following the instructions on the Network Advertising Initiative opt-out page: <a href="http://optout.networkadvertising.org/" target="_blank" rel="noreferrer">http://optout.networkadvertising.org/</a>.</p>
      </div>
      <div>
        <h2 className='jackie-blue'>Security</h2>
      </div>
      <div>
        <p>We take reasonable measures to help protect information about you from loss, theft, misuse, and unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or method of electronic storage is 100% secure, so we cannot guarantee its absolute security.</p>
      </div>
      <div>
        <h2 className='jackie-blue'>Children’s Privacy</h2>
      </div>
      <div>
        <p>Our site is not directed to children under 13, and we do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information.</p>
      </div>
      <div>
        <h2 className='jackie-blue'>Links to Other Sites</h2>
      </div>
      <div>
        <p>Our site may contain links to other websites not operated by us. If you click on a third-party link, you will be directed to that third party’s site. We strongly advise you to review the privacy policy and terms of service of any site you visit. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
      </div>
      <div>
        <h2 className='jackie-blue'>Contact Us</h2>
      </div>
      <div>
        <p>If you have any questions about this privacy policy, please contact us at the campaign support e-mail address listed at the beginning of this privacy policy (“campaign support”).</p>
      </div>
    </div>
  );
};

export default Privacy;
