import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Mailchimp from './MailchimpSignUp';

const Footer = () => {
  const { pathname } = useLocation();

  // Scroll to top on route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <footer className="footer bg-dark text-white pt-3 pb-5">
      <div className="container text-center">
        <div className='row'>
          <div className='col-12 pb-5'>
            {/* You can add any additional content here if needed */}
          </div>
          <div className='col-md-4'>
            <img id="footer-logo" className="img-fluid rounded-circle" src="/images/headshot.png" alt="logo"/>
          </div>
          <div className='col-md-4'>
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <Link className="text-white text-decoration-none" to="/">Home</Link>
              </li>
              <li>
                <Link className="text-white text-decoration-none" to="/about-jackie">About Jackie</Link>
              </li>
              <li>
                <Link className="text-white text-decoration-none" to="/endorsements">Endorsements</Link>
              </li>
              <li>
                <Link className="text-white text-decoration-none" to="/volunteer">Volunteer</Link>
              </li>
              <li>
                <Link className="text-white text-decoration-none" to="/donate">Donate</Link>
              </li>
              <li>
                <Link className="text-white text-decoration-none" to="/contact">Contact</Link>
              </li>
              <li>
                <Link className="text-white text-decoration-none" to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
          <div className='col-md-4'>
            <h5>Join Mailing List</h5>
            <Mailchimp />
          </div>
        </div>
      </div>
      <div>
        <p className='text-white mt-5 ms-3'>
          Paid for by Jackie For Sausalito<br/>#1474406
        </p>
      </div>
    </footer>
  );
};

export default Footer;
