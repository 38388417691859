import React from 'react';

const HeroSmall = () => {
  return (
    <div className='container-fluid'>
      <div className="row min-vh-75 align-items-center d-flex align-items-md-stretch">
        <div className="hero-section-sm col-md-12 p-0">
        </div>
      </div>
    </div>
  );
};

export default HeroSmall;
