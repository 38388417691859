import React from 'react';
import Donate from '../components/Donate'

const Contact = () => {
    return (
      <div className='container'>
            <h1 className="text-center display-4 fw-bold jackie-green my-5">Support Jackie</h1>
            <p className="text-center lead mb-5">Your contributions help fund our campaign to bring positive change to Sausalito.
            Thank you for your support!</p>
        <Donate/>
        </div>
    );
  };
  
  export default Contact;